import { getEnv, resolve } from 'core/utils/env';

import {
  lightBlue,
  lightBlueMobile,
  darkBlue,
  darkBlueMobile,
  lightRed,
  lightRedMobile,
  darkRed,
  darkRedMobile,
  dark,
  darkMobile,
} from './themes';

import { JOURNAL_URL } from 'site/constants';

const DEFAULT_HOST = 'quto.su';

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig(requestHeaders) {
  const hostName = requestHeaders
    ? resolve({
      'dev': DEFAULT_HOST,
      '*': requestHeaders.host,
    }) : DEFAULT_HOST;

  const siteUrl = 'https://' + hostName;

  return {
    siteName: 'quto',
    host: hostName,
    theme: 'light',
    // TODO: в LB захардкожены light и dark в amp. Нужно что-то с этим сделать
    // Без lightBlue и darkBlue сайт не работает.
    themes: {
      light: {
        base: lightBlue,
        mobile: lightBlueMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
      lightBlue: {
        base: lightBlue,
        mobile: lightBlueMobile,
      },
      darkBlue: {
        base: darkBlue,
        mobile: darkBlueMobile,
      },
      lightRed: {
        base: lightRed,
        mobile: lightRedMobile,
      },
      darkRed: {
        base: darkRed,
        mobile: darkRedMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.quto.su/api/v2',
      }),
    },

    servicesApi: resolve({
      '*': 'https://stage.quto.su/unity/v1',
      'production': 'https://quto.su/unity/v1',
    }),

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/quto',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: `${siteUrl}/erratum`,

    bannersForCapirsMonitor: [
      433009906,
      579291641,
    ],

    isUnityProject: true,

    schemaOrg: {
      organizationName: 'Quto',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 180,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s - quto.su',
      defaultTitle: 'Автомобильный журнал Quto: новости и эксклюзивы, практические советы, история, подробный каталог и актуальные цены',
      meta: [
        { name: 'description', content: 'Quto - последние новости авторынка, тест-драйвы, обзоры экспертов' },
      ],
    },

    ramblerCommentsAppId: resolve({
      'production': 37,
      '*': 193,
    }),

    ads: {
      siteId: '433006318',
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
      isNewVersionPremiumTopic: true,
      enableFenwick: true,
      enableCapirsMonitor: true,
    },

    counterIds: {
      mailru: 3090296,
      googleAnalytics: 'UA-16232570-1',
      ga4: 'G-GHVTT3VF5C',
      tns: { account: 'sup_ru', tmsec: 'quto_other' },
      mediascope: 'rambler_cid1100108-posid2155110/',
      top100: 2271849,
      yandexMetrika: 1188885,
      liveInternet: { ids: ['Lenta', 'gazeta_all'] },
      rcm: resolve({
        '*': 'RCM-DF62',
        'production': 'RCM-B5A2',
      }),
      loyalty: true,
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': '6A59A-59CF3',
        'staging': 'E882A-16AD5',
      }),
      safariWebsitePushID: 'web.quto.su',
      defaultNotificationTitle: '',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    mediaUrlPrefix: JOURNAL_URL,

    specialLinks: [
      '/about',
    ],

    spammer: {
      ramblerCrmProjectKey: 'quto',
      digestKey: 'digest',
      termsUrl: 'https://help.rambler.ru/legal/1706/',
    },

    ramblerId: {
      rname: 'quto',
      useAuthRedirect: true,
    },

    socials: [
      { name: 'Vkontakte', link: 'https://vk.com/quto_ru' },
      { name: 'Odnoklassniki', link: 'https://ok.ru/qutoru' },
      { name: 'Yandex_Dzen', link: 'https://dzen.ru/quto.su?favid=1670' },
    ],
  };
}
