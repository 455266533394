import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import Link from 'core/components/Link';
import Scooter from 'core/components/Scooter';
import withGeo from 'site/components/GeoContext/withGeo';


function SiteScooter(props) {
  const {
    getGeoAttributes,
    geoSlug,
    location,
  } = props;

  const geoName = geoSlug && getGeoAttributes(geoSlug).name;

  return (
    <Scooter
      services={[
        { link: '/about', content: 'Редакция' },
        { link: '/about#advertising', content: 'Размещение рекламы' },
        { link: '/legal', content: 'Правовая информация' },
        { link: '/legal/terms-of-use', content: 'Условия использования' },
        { link: '/legal/privacy', content: 'Политика конфиденциальности' },
        { link: { pathname: '/geo', state: { from: location } }, content: geoSlug && <Fragment>Регион: <b>{geoName}</b></Fragment> },
      ]}
      commercial={[
        'Все права защищены. Полное или частичное копирование материалов Сайта в коммерческих целях разрешено только с письменного разрешения владельца Сайта. В случае обнаружения нарушений, виновные лица могут быть привлечены к ответственности в соответствии с действующим законодательством Российской Федерации.',
        {
          body: <Link type='tertiary' to='/rules-recommenders'>На информационном ресурсе применяются рекомендательные технологии в соответствии с Правилами</Link>,
        },
      ]}
      contacts={{
        site: 'quto.su',
        age: 16,
        phone: {
          caption: 'Телефон редакции:',
          body: '+7 495 785-17-00',
        },
      }}
    />
  );
}

SiteScooter.propTypes = {
  getGeoAttributes: PropTypes.func.isRequired,
  geoSlug: PropTypes.string,
  location: PropTypes.object,
};

export default withGeo(withRouter(SiteScooter));
